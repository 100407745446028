import { useState, useContext, useEffect, useRef, useId } from "react";
import { Breadcrumb, Button, Dropdown, Space, Tabs, Tag, Tooltip, Typography,Modal } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  DownSquareFilled,
  EditOutlined,
  EyeOutlined,
  ScheduleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { push } from "redux-first-history";
import {
  useDeleteReportBookmarkMutation,
  useDeleteReportMutation,
  useGetAllReportsQuery,
  useGetBookmarkReportsQuery,
  useGetPrivateReportsQuery,
  useGetPublicReportsQuery,
  useGetRecentReportsQuery,
  useGetReportsCreatedByMeQuery,
  useGetSharedReportsQuery,
} from "./store/report-api-slice";
import type { ColumnsType } from "antd/es/table";
import { Report } from "../../types/report";
import { useParams } from "react-router-dom";
import { HeaderReportButtons } from "./layout/reports-config";
import { AppContext, store, useAppDispatch } from "../../app/store";
import { layoutSlice } from "../../layout/store/layout-slice";
import List, { TableParams } from "../../layout/components/list";
import { ModalUtility } from "../../layout/components/modal-manager";
import { IShareModal, ShareType } from "../users/components/share-modal";
import { CloneType, ICloneModal } from "../../layout/components/clone-modal";
import { hasPermission } from "../auth/store/auth-slice";
import { PERMISSIONS } from "../auth/permissions/permission-maps";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { ItemType } from "antd/es/menu/hooks/useItems";
import './bookmarks-listing.css'
import { useDeleteDashboardBookmarkMutation, useDeleteDashboardMutation, useGetAllDashboardsQuery, useGetBookmarkDashboardsQuery } from "../dashboards/store/dashboard-api-slice";
import { Dashboard } from "../../types/dashboard";
const {Text, Link} = Typography;
export enum BookmarksListType {
  Reports = 1,
  Dashboards
}
type props = {
  type: BookmarksListType;
};
const getReportBookmarks = () => {
  const bookmarks = localStorage.getItem('reports-bookmarks');
  if(bookmarks){
    return new Set<string>(JSON.parse(bookmarks));
  }
  else{
    return new Set<string>();
  }
}
const getDashboardBookmarks = () => {
  const bookmarks = localStorage.getItem('dashboard-bookmarks');
  if(bookmarks){
    return new Set<string>(JSON.parse(bookmarks));
  }
  else{
    return new Set<string>();
  }
}
export const BookmarksList = ({ type }: props) => {
  const { folderId } = useParams();
  const dispatch = useAppDispatch();
  const [appContext] = useContext(AppContext);
  const [deleteDashboard] = useDeleteDashboardMutation();
  const [deleteReportBookmark] = useDeleteReportBookmarkMutation()
  const [deleteDashboardBookmark] = useDeleteDashboardBookmarkMutation()
  const [columns, setColumns] = useState<any>();
  const getHeaderTitle = () => {
      return "Bookmarks"
  }

  // const [reportBookmarks, setReportBookmarks] = useState<Set<string>>(getReportBookmarks());
  // const [dashboardBookmarks, setDashboardBookmarks] = useState<Set<string>>(getDashboardBookmarks());
  const removeReportBookmark = (reportId:number) => {
    deleteReportBookmark(reportId)
    // setReportBookmarks(bookmarks => {
    //   bookmarks.delete(reportId.toString());
    //   localStorage.setItem('reports-bookmarks', JSON.stringify(Array.from(bookmarks)));
    //   return new Set<string>(Array.from(bookmarks));
    // });
  }
  const removeDashboardBookmark = (dashboardId:number) => {
    deleteDashboardBookmark(dashboardId)
    // setDashboardBookmarks(bookmarks => {
    //   bookmarks.delete(reportId.toString());
    //   localStorage.setItem('dashboard-bookmarks', JSON.stringify(Array.from(bookmarks)));
    //   return new Set<string>(Array.from(bookmarks));
    // });
  }

  useEffectOnce(() => {
    dispatch(layoutSlice.actions.updateTitle(getHeaderTitle()));
    dispatch(layoutSlice.actions.updateShowSider(false));
    dispatch(layoutSlice.actions.updateShowSubHeader(false));
    dispatch(layoutSlice.actions.updateShowGoBackButton(false));
  });
  useEffect(() => {
    dispatch(layoutSlice.actions.updateSetLayoutClass("listing-page"));
    dispatch(layoutSlice.actions.updateSubTitle(''));
    let buttons = [HeaderReportButtons.NewReport];
    if (folderId) {
      buttons = [...buttons, HeaderReportButtons.EditFolder];
    }
    buttons = [
    //   ...buttons,
    //   // HeaderReportButtons.ViewAllDashboards,
    //   // HeaderReportButtons.ViewAllSchedules,
    //   HeaderReportButtons.MoreOptions,
    ];
    dispatch(layoutSlice.actions.updateButtonsToShow(buttons));
 
    return function () {
      dispatch(layoutSlice.actions.updateSetLayoutClass(""))
    }
  }, []);
  useEffect(() => {
    dispatch(layoutSlice.actions.updateTitle(getHeaderTitle()));
    changeColumnType(type);
  }, [type, folderId])
  const changeColumnType = (type: BookmarksListType) => {
    if(type===BookmarksListType.Dashboards) {
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (name:any, data:any) => (
             <div style={{display:'flex',justifyContent:'space-between',paddingRight:"4.15px"}}>
                <Link href={`${appContext.basepath}/dashboards/${data.id}`} onClick={(e) => dashboardLink(e, data)} style={{fontWeight: '500', fontSize: '13px', lineHeight: '15.73px'}}>{name}</Link>
                {/* <DownSquareFilled style={{color:"#0055FF",fontSize:"15px"}}/> */}
              </div>
            // <Tooltip placement="topLeft" className="td-name" title={name}>
            //   {name}
            // </Tooltip>
          ),
        },
        {
          title: "Description",
          key: "description",
          dataIndex: "description",
          sorter: true,
          render: (description: string) =>
            description ? (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        {
          title: "Owner",
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (createdBy:any) => (
            <Tooltip placement="topLeft" className="td-owner" title={createdBy}>
              {createdBy}
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_:any, dashboard:any) => (
            <Space size="middle">
              <Tooltip title="View">
                <Button
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(push(`${appContext.basepath}/dashboards/${dashboard.id}`));
                    return false;
                  }}
                />
              </Tooltip>
              <Tooltip title="Remove from bookmark">
                <Button
                    style={{paddingRight:0,paddingLeft:0,width:'32px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    Modal.confirm({
                      title: 'Remove from bookmark',
                      content: `Are you sure you want to remove ${dashboard.name} from bookmarks?`,
                      okText: 'Confirm',
                      icon: ' ',
                      onOk: () => 
                      removeDashboardBookmark(dashboard.id),
                      onCancel: () => {}, 
                    });
              
                    return false;
                  }}
                >
                    <span className="material-symbols-outlined" style={{cursor:'pointer'}}>bookmark_remove</span>
                  </Button>
              </Tooltip>
              {hasPermission(store.getState(), [
                PERMISSIONS.editDashboard,
                PERMISSIONS.deleteDashboard,
                PERMISSIONS.scheduleDashboard,
                PERMISSIONS.cloneDashboard,
                PERMISSIONS.shareDashboard,
              ]) ? (
                <Dropdown.Button
                  type="default"
                  menu={{
                    items: [
                      hasPermission(store.getState(), PERMISSIONS.editDashboard)
                        ? {
                            key: 1,
                            label: "Edit Details",
                            disabled: !dashboard.allowEdit,
                            icon: <EditOutlined />,
                            onClick: () =>
                              dispatch(push(`${appContext.basepath}/dashboards/${dashboard.id}/edit`)),
                          }
                        : null,
                      hasPermission(store.getState(), PERMISSIONS.deleteDashboard)
                        ? {
                            key: 2,
                            label: "Delete Dashboard",
                            disabled: !dashboard.allowEdit,
                            icon: <DeleteOutlined />,
                        onClick: () => {
                          Modal.confirm({
                            title: 'Confirmation',
                            content: `Are you sure you want to delete ${dashboard.name}`,
                            okText: 'Confirm',
                            icon: ' ',
                            onOk: async () => await deleteDashboard(dashboard.id),
                            onCancel: () => {},
                          });
                        },
                      }
                    : null,
                      ...(hasPermission(
                        store.getState(),
                        PERMISSIONS.scheduleDashboard
                      )
                        ? [
                            { type: "divider" } as ItemType,
                            {
                              key: 3,
                              label: "New Schedule",
                              icon: <ScheduleOutlined />,
                              onClick: () =>
                                dispatch(
                                  push(`${appContext.basepath}/dashboards/${dashboard.id}/schedules/add`)
                                ),
                            },
                            {
                              key: 4,
                              label: "View Schedules",
                              icon: <ScheduleOutlined />,
                              onClick: () =>
                                dispatch(
                                  push(`${appContext.basepath}/dashboards/${dashboard.id}/schedules`)
                                ),
                            },
                          ]
                        : []),
                      ...(hasPermission(
                        store.getState(),
                        PERMISSIONS.cloneDashboard
                      )
                        ? [
                            { type: "divider" } as ItemType,
                            {
                              key: 5,
                              label: "Clone",
                              icon: <CopyOutlined />,
                              onClick: () => cloneShareModal(dashboard.id),
                            },
                          ]
                        : []),
                      // ...(hasPermission(
                      //   store.getState(),
                      //   PERMISSIONS.shareDashboard
                      // )
                      //   ? [
                      //       { type: "divider" } as ItemType,
                      //       {
                      //         key: 6,
                      //         label: "Share",
                      //         disabled:
                      //           !dashboard.private || !dashboard.createdByMe,
                      //         icon: <ShareAltOutlined />,
                      //         onClick: () => showShareModal(dashboard.id),
                      //       },
                      //     ]
                      //   : []),
                    ],
                  }}
                ></Dropdown.Button>
              ) : null}
            </Space>
          ),
        },
      ])
    }
    else{
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (name:any, data:any) => {
    
            return (
              <div style={{display:'flex',justifyContent:'space-between',paddingRight:"4.15px"}}>
                <Link href={`${appContext.basepath}/reports/${data.id}`} onClick={(e) => gotoLink(e, data)}>{name}</Link>
                {/* <DownSquareFilled style={{color:"#0055FF",fontSize:"15px"}}/> */}
              </div>
            )
    
            
            // <Tooltip className="td-name" placement="topLeft" title={name}>
            //   <Link target="_blank">{name}</Link>
            // </Tooltip>
          },
        },
        {
          title: "Description",
          key: "description",
          dataIndex: "description",
          sorter: true,
          render: (description: string) =>
            description ? (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        {
          title: "Collection",
          key: "folder",
          dataIndex: "folder",
          sorter: true,
          render: (folder: string) => ((folder || "")),
        },
        
        {
          title: "Owner",
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (createdBy:any) => (
            <Tooltip className="td-owner" placement="topLeft" title={createdBy}>
              {createdBy}
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_:any, report:any) => (
            <Space size="middle">
              {hasPermission(store.getState(), PERMISSIONS.viewReport) ? (
                <Tooltip title="View">
                <Button
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(push(`${appContext.basepath}/reports/${report.id}`));
                    return false;
                  }}
                />
              </Tooltip>
              ) : (
                ""
              )}

                <Tooltip title="Remove from bookmark">
                  <Button
                    style={{paddingRight:0,paddingLeft:0,width:'32px'}}
                    onClick={(e) => {
                      e.preventDefault();
                      Modal.confirm({
                        title: 'Remove from bookmark',
                        content: `Are you sure you want to remove ${report.id} from bookmarks?`,
                        okText: 'Confirm',
                        icon: ' ',
                        onOk: () => 
                        removeReportBookmark(report.id),
                        onCancel: () => {}, 
                      });
                
                      return false;
                    }}
                  >
                    <span className="material-symbols-outlined" style={{cursor:'pointer'}}>bookmark_remove</span>
                  </Button>
                </Tooltip>
    
              {hasPermission(store.getState(), [
                PERMISSIONS.viewReport,
                PERMISSIONS.editReport,
                PERMISSIONS.deleteReport,
                PERMISSIONS.scheduleReport,
                PERMISSIONS.cloneReport,
                PERMISSIONS.shareReport,
              ]) ? (
                <Dropdown.Button
                  type="default"
                  menu={{
                    items: [
                      hasPermission(store.getState(), PERMISSIONS.editReport)
                        ? {
                            key: 1,
                            label: "Edit Details",
                            disabled: !report.allowEdit,
                            icon: <EditOutlined />,
                            onClick: () =>
                              dispatch(push(`${appContext.basepath}/reports/${report.id}/edit`)),
                          }
                        : null,
                      hasPermission(store.getState(), PERMISSIONS.deleteReport)
                        ? {
                            key: 2,
                            label: "Delete Report",
                            disabled: !report.allowEdit,
                            icon: <DeleteOutlined />,
                            onClick: () => {
                              Modal.confirm({
                                title: 'Confirmation',
                                content: `Are you sure you want to delete the ${report.name}`,
                                okText: 'Confirm',
                                icon: ' ',
                                onOk: async () => await deleteReport(report.id),
                                onCancel: () => {},
                              });
                            },
                          }
                        : null,
                      ...(hasPermission(
                        store.getState(),
                        PERMISSIONS.scheduleReport
                      )
                        ? [
                            { type: "divider" } as ItemType,
                            {
                              key: 3,
                              label: "New Schedule",
                              icon: <ScheduleOutlined />,
                              onClick: () =>
                                dispatch(
                                  push(`${appContext.basepath}/reports/${report.id}/schedules/add`)
                                ),
                            },
                            {
                              key: 4,
                              label: "View Schedules",
                              icon: <ScheduleOutlined />,
                              onClick: () =>
                                dispatch(push(`${appContext.basepath}/reports/${report.id}/schedules`)),
                            },
                          ]
                        : []),
                      ...(hasPermission(store.getState(), PERMISSIONS.cloneReport)
                        ? [
                            { type: "divider" } as ItemType,
                            {
                              key: 5,
                              label: "Clone",
                              icon: <CopyOutlined />,
                              onClick: () => cloneShareModal(report.id),
                            },
                          ]
                        : []),
                      // ...(hasPermission(store.getState(), PERMISSIONS.shareReport) && report.private
                      //   ? [
                      //       { type: "divider" } as ItemType,
                      //       {
                      //         key: 6,
                      //         label: "Share",
                      //         disabled: !report.private || !report.createdByMe,
                      //         icon: <ShareAltOutlined />,
                      //         onClick: () => showShareModal(report.id),
                      //       },
                      //     ]
                      //   : []),
                    ],
                  }}
                ></Dropdown.Button>
              ) : null}
            </Space>
          ),
        },
      ])
    }
  }
  const shareModalRef = ModalUtility.getPropertyByName("shareModalRef");
  const cloneModalRef = ModalUtility.getPropertyByName("cloneModalRef");
  const showShareModal = (reportId: number) => {
    (shareModalRef.current as IShareModal)?.changeId(reportId);
    (shareModalRef.current as IShareModal)?.changeType(ShareType.Report);
    shareModalRef?.current?.toggleModal();
  };
  const cloneShareModal = (reportId: number) => {
    (cloneModalRef.current as ICloneModal)?.changeId(reportId);
    (cloneModalRef.current as ICloneModal)?.changeType(CloneType.Report);
    cloneModalRef?.current?.toggleModal();
  };
  const [deleteReport] = useDeleteReportMutation();
  const gotoLink = (e: any, data: any) => {
    e.preventDefault();
    // console.log(data)
    dispatch(push(`${appContext.basepath}/reports/${data.id}`))
  }
  const dashboardLink = (e: any, data: any) => {
    e.preventDefault();
    // console.log(data)
    dispatch(push(`${appContext.basepath}/dashboards/${data.id}`))
  }
  
  /** Pagination */
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  });
  const [tableParamsDashboard, setTableParamsDashboard] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  });
  const { data: allReports } = useGetBookmarkReportsQuery(
    {
      page: tableParams.pagination?.current || 0,
      per_page: 1000,
      sortBy: tableParams.sortField,
      sortOrder: tableParams.sortOrder,
    },
    {
      skip: !(type === BookmarksListType.Reports),
    }
  );
  const { data: allDashboards } = useGetBookmarkDashboardsQuery(
    {
      page: tableParamsDashboard.pagination?.current || 1,
      per_page: tableParamsDashboard.pagination?.pageSize || 10,
      sortBy: tableParamsDashboard.sortField,
      sortOrder: tableParamsDashboard.sortOrder,
    },
    {
      skip: !(type === BookmarksListType.Dashboards),
    }
  );
  var bookmarksList: any ;
  switch (type) {
    case BookmarksListType.Reports:
      bookmarksList = allReports!;
      // if(bookmarksList) {
      //   bookmarksList = {...bookmarksList,pageSize:100,totalCount:100, items: bookmarksList.items.filter((report: Report) => reportBookmarks.has(report.id.toString()))}
      // }
      break;
    case BookmarksListType.Dashboards:
      bookmarksList = allDashboards!;
      // if(bookmarksList) {
      //   bookmarksList = {...bookmarksList,pageSize:100,totalCount:100, items: bookmarksList.items.filter((dashboard: Dashboard) => dashboardBookmarks.has(dashboard.id.toString()))}
      // }
      break;
  }
  return (
    <>
    <Tabs
      defaultActiveKey={type === BookmarksListType.Reports ? "reports" : "dashboards"}
      onChange={event => {
        dispatch(push(`${appContext.basepath}/bookmarks/${event}`));
      }}
      destroyInactiveTabPane={true}
      tabBarStyle={{marginBottom: 0}}
      style={{height: '100%', overflow: 'hodden'}}
      items={[
        {
          label: 'Reports',
          key: "reports",
          children: <List columns={columns} data={bookmarksList} setTableParams={setTableParams}  />
        },
        {
          label: 'Dashboards',
          key: "dashboards",
          children: <List columns={columns} data={bookmarksList} setTableParams={setTableParamsDashboard} />
        }
      ]}
    />
    </>
    
  );
};
export default BookmarksList;