import { api } from "../../../app/baseQueryWithReauth";
import { Clone } from "../../../types/clone";
import {
  Dashboard,
  DashboardAdd,
  DashboardEmbed,
} from "../../../types/dashboard";
import { Schedule } from "../../../types/schedule";
import { Template } from "../../../types/template";
import {
  DashboardRemoveShareUser,
  DashboardSharePermissionUser,
  DashboardShareUser,
  SharedUser,
  DashboardUpdateOwner,
} from "../../../types/user";

export const dashboardApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDashboards: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getAllDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
    getRecentDashboards: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getRecentDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
    getDashboardsCreatedByMe: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getDashboardsCreatedByMe?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
    getPrivateDashboards: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getPrivateDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),


    getBookmarkDashboards: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/GetBookmarkDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) => ["Dashboard", "Bookmark"],
      }),


    getPublicDashboard: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getPublicDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),


    getArchivedDashboards: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; }
    >({
      query: ({
        page = 1,
        per_page = 10,
      }) => {
        return `/Dashboard/GetArchivedDashboards?pageNumber=${page}&pageSize=${per_page}`;
      },
      providesTags: (result) => ["Dashboard", "Archived"],
      }),


    getSharedDashboard: builder.query<
      ListResponse<Dashboard>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getSharedDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Dashboard" as const,
                id,
              })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
    getDashboardTemplates: builder.query<Template[], void>({
      query: () => "/dashboard/getTemplates",
      providesTags: ["Dashboard"],
    }),
    getDashboard: builder.query<DashboardAdd, number>({
      query: (dashboardId) => `/dashboard/${dashboardId}`,
      transformResponse: (response: Dashboard) => {
        return {
          id: response.id,
          name: response.name,
          description: response.description,
          templateId: response.templateId,
          private: response.private,
          published: response.published,
        };
      },
      providesTags: (result, error, id) => [{ type: "Dashboard", id }],
    }),
    getDashboardEmbed: builder.query<DashboardEmbed, number>({
      query: (dashboardId) =>
        `/dashboard/GetDashboardEmbed?DashboardId=${dashboardId}`,
      providesTags: (result, error, id) => [{ type: "Dashboard", id }],
    }),
    createDashboard: builder.mutation<number, Partial<DashboardAdd>>({
      query: (body) => ({
        url: "/dashboard",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    unArchiveDashboard: builder.mutation<number, {dashboardId: number}>({
      query: (body) => ({
        url: "/Dashboard/UnArchiveDashboard",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Archived"],
    }),

    updateDashboard: builder.mutation<number, Partial<DashboardAdd>>({
      query: (patch) => ({
        url: `/dashboard/${patch.id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Dashboard", id }],
    }),
    deleteDashboard: builder.mutation<{ success: boolean; id: number }, number>(
      {
        query(id) {
          return {
            url: `/dashboard/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["Dashboard"],
      }
    ),
    getScheduleDashboards: builder.query<
      ListResponse<Schedule>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/dashboard/getAllDashboards?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "ScheduleDashboard" as const,
                id,
              })),
              { type: "ScheduleDashboard", id: "LIST" },
            ]
          : [{ type: "ScheduleDashboard", id: "LIST" }],
    }),
    dashboardSharedWith: builder.query<SharedUser[], number>({
      query: (dashboardId) =>
        `/dashboard/sharedWith?dashboardId=${dashboardId}`,
      providesTags: (result, error, id) =>
        result
          ? [
              ...result.map(({ userId }) => ({
                type: "SharedDashboardUsers" as const,
                userId,
              })),
              { type: "SharedDashboardUsers", id: `LIST-${id}` },
            ]
          : [{ type: "SharedDashboardUsers", id: `LIST-${id}` }],
    }),
    shareDashboard: builder.mutation<number, Partial<DashboardShareUser>>({
      query: (body) => ({
        url: "/dashboard/share",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["SharedDashboardUsers"],
    }),
    changeSharePermissionDashboard: builder.mutation<
      number,
      Partial<DashboardSharePermissionUser>
    >({
      query: (body) => ({
        url: `/dashboard/changeSharePermission`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["SharedDashboardUsers"],
    }),
    removeShareDashboard: builder.mutation<
      number,
      Partial<DashboardRemoveShareUser>
    >({
      query: (body) => ({
        url: `/dashboard/removeShare/${body.dashboardId}/${body.userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SharedDashboardUsers"],
    }),
    cloneDashboard: builder.mutation<number, Partial<Clone>>({
      query: (body) => ({
        url: "/dashboard/clone",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Dashboard", "Folder"],
    }),



    addDashboardBookmark: builder.mutation<number, number>({
      query: (body) => ({
        url: "/Dashboard/Bookmark",
        method: "POST",
        body: {dashboardId: body},
      }),
      invalidatesTags: ["Dashboard", "Bookmark"],
    }),

    deleteDashboardBookmark: builder.mutation<number, number>({
      query: (body) => ({
        url: "/Dashboard/DeleteBookmark",
        method: "DELETE",
        body: {dashboardId: body},
      }),
      invalidatesTags: ["Dashboard", "Bookmark"],
    }),

    updateDashboardOwner: builder.mutation<
      number,
      Partial<DashboardUpdateOwner>
    >({
      query: (body) => ({
        url: `/dashboard/changeOwner/${body.dashboardId}/${body.userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["SharedDashboardUsers", "Dashboard"],
    }),
  }),
});

export const {
  useGetAllDashboardsQuery,
  useGetRecentDashboardsQuery,
  useGetDashboardsCreatedByMeQuery,
  useGetPrivateDashboardsQuery,
  useGetPublicDashboardQuery,
  useGetSharedDashboardQuery,
  useGetDashboardQuery,
  useGetDashboardEmbedQuery,
  useGetDashboardTemplatesQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useUpdateDashboardOwnerMutation,
  useDeleteDashboardMutation,
  useGetScheduleDashboardsQuery,
  useDashboardSharedWithQuery,
  useShareDashboardMutation,
  useChangeSharePermissionDashboardMutation,
  useRemoveShareDashboardMutation,
  useCloneDashboardMutation,
  useGetBookmarkDashboardsQuery,
  useAddDashboardBookmarkMutation,
  useDeleteDashboardBookmarkMutation,
  useGetArchivedDashboardsQuery,
  useUnArchiveDashboardMutation
} = dashboardApiSlice;
