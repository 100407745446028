import styled from "styled-components";

export const AccessDeniedStyles = styled.div`
  width: 100%;

  .text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 1rem;
    font-weight: 700;
    color: #ee4b5e;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 700;
    color: #1fa9d6;
  }
`;
