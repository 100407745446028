import {
    createSlice,
    PayloadAction,
  } from "@reduxjs/toolkit";
  export interface SearchState {
    searchString : string,
    isVisible: boolean;
  }
  
  const initialState: SearchState = {
     searchString : '',
     isVisible: false,
  };
export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
      updateSearchString(state, action: PayloadAction<string>) {
        state.searchString = action.payload;
      },
      toggleSearchVisibility(state,  action: PayloadAction<boolean>) {
        state.isVisible = action.payload;
      },
    },
  });
export const { updateSearchString, toggleSearchVisibility } = searchSlice.actions;

export default searchSlice.reducer;
  

  