import styled from "styled-components";

export const ListStyles = styled.div`
  .ant-dropdown-button {
    button:first-child {
      display: none;
    }
  }

  table, .ant-pagination {
    font-size:1rem;
  }

  .td-name, .td-owner{
    display: block;
    word-wrap: break-word;
    white-space: normal;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
`;