import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { push } from "redux-first-history";
import { AppContext, useAppDispatch, useTypedSelector } from "../../app/store";
import {} from "../../layout/components/AccessDenied";
import { PERMISSIONS } from "./permissions/permission-maps";
import { onAccessDenied } from "./store/auth-slice";

type props = {
  permission: PERMISSIONS;
  children?: JSX.Element;
};

export const ProtectedRoute = ({ permission, children }: props) => {
  const [appContext] = useContext(AppContext)
  const dispatch = useAppDispatch();
  const { isInitialized, permissions } = useTypedSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isInitialized && !permissions.some((p) => p === permission)) {
      dispatch(
        onAccessDenied({
          onAccessDeniedPermission: permission,
        })
      );
      dispatch(push(`${appContext.basepath}/access-denied`));
    }

  }, [dispatch, isInitialized, permissions, permission]);

  if (isInitialized && !permissions.some((p) => p === permission)) {
    return <></>;
  }

  return children ? children : <Outlet />;
};
