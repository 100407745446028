
export function toPascalCase(input?: string | null) {
    // Check if input is empty string
    if (!input || !input.trim()) {
      return null;
    }
  
    // Remove leading and trailing spaces, split by space or underscore
    let words = input.trim().split(/[ _]/);
  
    // Capitalize the first letter of each word
    let pascalCase = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  
    // Join the words without spaces
    return pascalCase.join('');
}
  