import notification from "antd/es/notification";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
/**
 * Log a warning and show a notification!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      if (action.error.status === 403)
        notification.error({
          message: "You don't have permission to access this page!",
        });
    }

    return next(action);
  };
