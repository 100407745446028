import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { RootState } from "./store";

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
  const baseUrl = `${(WebApi.getState() as RootState).auth.apiUrl}/${
    (WebApi.getState() as RootState).auth.tenantId
  }`;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers, { getState }) {
      const accessToken = (getState() as RootState).auth.accessToken;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

// export const baseQueryWithRetry = retry(dynamicBaseQuery, { maxRetries: 3 ,
//   retryCondition: (error: FetchBaseQueryError) => error.status !== 403 });

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, webapi, extraOptions) => {
  const result = await dynamicBaseQuery(args, webapi, extraOptions);
  // webapi.dispatch(api.util.invalidateTags(["Roles"]));
  // if (result.error) {
  //   if (result.error.status === 401) {
  //   } else if (result.error.status === 403) {
  //   }
  //   // try to get a new token
  //   // const refreshResult = await baseQueryWithRetry(
  //   //   "/refreshToken",
  //   //   api,
  //   //   extraOptions
  //   // );
  //   // if (refreshResult.data) {
  //   //   // store the new token
  //   //   //   api.dispatch(tokenReceived(refreshResult.data))
  //   //   // retry the initial query
  //   //   result = await baseQueryWithRetry(args, api, extraOptions);
  //   // } else {
  //   //   // api.dispatch(authSlice.actions.logout())
  //   // }
  // }
  return result;
};

const baseQueryWithoutTenant: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
  const baseUrl = `${(WebApi.getState() as RootState).auth.apiUrl}`;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers, { getState }) {
      const accessToken = (getState() as RootState).auth.accessToken;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export const baseQueryWithReauthWithoutTenant: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, webapi, extraOptions) => {
  const result = await baseQueryWithoutTenant(args, webapi, extraOptions);
  // webapi.dispatch(api.util.invalidateTags(["Roles"]));
  // if (result.error) {
  //   if (result.error.status === 401) {
  //   } else if (result.error.status === 403) {
  //   }
  //   // try to get a new token
  //   // const refreshResult = await baseQueryWithRetry(
  //   //   "/refreshToken",
  //   //   api,
  //   //   extraOptions
  //   // );
  //   // if (refreshResult.data) {
  //   //   // store the new token
  //   //   //   api.dispatch(tokenReceived(refreshResult.data))
  //   //   // retry the initial query
  //   //   result = await baseQueryWithRetry(args, api, extraOptions);
  //   // } else {
  //   //   // api.dispatch(authSlice.actions.logout())
  //   // }
  // }
  return result;
};

export const allTagTypes = [
  "Folder",
  "Report",
  "Dashboard",
  "ScheduleReport",
  "ScheduleDashboard",
  "Users",
  "SharedReportUsers",
  "SharedDashboardUsers",
  "Roles",
];
/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "splitApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithReauth,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: allTagTypes,
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
export const apiWithoutTenant = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "splitApiWithoutTenant",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithReauthWithoutTenant,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: allTagTypes,
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
