import { IFoldersModal } from "../../features/folders/folders-modal";
import { IShareModal } from "../../features/users/components/share-modal";
import { ICloneModal } from "./clone-modal";

type modalState = {
  [id: string]: React.RefObject<IShareModal | IFoldersModal | ICloneModal>;
};

export class ModalUtility {
  static instance: ModalUtility;
  _globalState: modalState = {};

  static get getInstace() {
    if (ModalUtility.instance == null) {
      ModalUtility.instance = new ModalUtility();
    }

    return this.instance;
  }

  static getPropertyByName(propertyName: string) {
    return ModalUtility.getInstace._globalState[propertyName];
  }

  static setPropertyValue(
    propertyName: string,
    propertyValue: React.RefObject<IShareModal | IFoldersModal | ICloneModal>
  ) {
    ModalUtility.getInstace._globalState[propertyName] = propertyValue;
  }
}
