import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import '../index.scss'
import { DashboardsListType } from "../features/dashboards/layout/dashboards-config";
import {
  FoldersListType,
  ReportsListType,
  TenantsListType,
} from "../features/reports/layout/reports-config";
import { ProtectedRoute } from "../features/auth/protected-route";
import { PERMISSIONS } from "../features/auth/permissions/permission-maps";
import { lazy, Suspense } from "react";
import DashboardsTemplates from "../features/dashboards/dashboards-templates";
import { AppContext } from "../app/store";
import CreateNew from "../features/common/create-new";
import FoldersAll from "../features/folders/folder-all";
import StandardReports from "../features/common/standard-reports";
import AdminStandardReports from "../features/common/admin-standard-reports";
import { TenantsList } from "../features/reports/tenants-listing";
import { ScheduleListing } from "../features/schedules/schedule-cron-listing";
import MainLayout from "../layout/main-layout";
import BookmarksList from "../features/reports/bookmarks-listing"
import { BookmarksListType } from "../features/reports/bookmarks-listing";
import ArchiveList from "../features/reports/archive-listing";
import { ArchiveListType } from "../features/reports/archive-listing";
const pkg = require('../../package.json')

const Auth = lazy(() => import("../features/auth/auth"));

const ReportLayout = lazy(
  () => import("../features/reports/layout/reports-layout")
);
const ReportsAdd = lazy(() => import("../features/reports/reports-add"));
const TenantAdd = lazy(() => import("../features/reports/tenants-add"));
const ReportsEdit = lazy(() => import("../features/reports/reports-edit"));
const ReportsList = lazy(() => import("../features/reports/reports-listing"));
const ReportsView = lazy(() => import("../features/reports/reports-view"));
const ReportsSchedules = lazy(
  () => import("../features/reports/reports-schedules")
);
const ReportsSchedulesAdd = lazy(
  () => import("../features/reports/reports-schedules-add")
);
const ReportsSchedulesEdit = lazy(
  () => import("../features/reports/reports-schedules-edit")
);

const DashboardLayout = lazy(
  () => import("../features/dashboards/layout/dashboards-layout")
);
const DashboardsAdd = lazy(
  () => import("../features/dashboards/dashboards-add")
);
const DashboardsEdit = lazy(
  () => import("../features/dashboards/dashboards-edit")
);
const DashboardsList = lazy(
  () => import("../features/dashboards/dashboards-list")
);
const DashboardsView = lazy(
  () => import("../features/dashboards/dashboards-view")
);
const DashboardsSchedules = lazy(
  () => import("../features/dashboards/dashboards-schedules")
);
const DashboardsSchedulesAdd = lazy(
  () => import("../features/dashboards/dashboards-schedules-add")
);
const DashboardsSchedulesEdit = lazy(
  () => import("../features/dashboards/dashboards-schedules-edit")
);

const FoldersList = lazy(() => import("../features/folders/folders-list"));

const AccessDenied = lazy(() => import("../layout/components/AccessDenied"));

const GlossaryReport = lazy(
  () => import("../features/reports/glossary-report-view")
);

const ReportsTemplates = lazy(() => import("../features/reports/reports-templates"));

const MainRoutes = ({ basepath }: { basepath: string }) => {
  const [appContext, setAppContext] = useState({ basepath })

  const location = useLocation();
  
  useEffect(() => {
    if(window.parent) {
      window.parent.postMessage({type: 'routeChange', args: [location.pathname]}, '*')
    }
    console.log('Location changed!', location.pathname);
  }, [location]);

  return (
    <Suspense>

      <AppContext.Provider value={[appContext, setAppContext]}>
        <span style={{ display: "none" }}>version: {pkg.version}</span>
        <Routes>
          <Route path={`${appContext.basepath}/`} element={<Auth />}>
            <Route
              path={`${appContext.basepath}/schedules`}
              element={<ReportLayout />}
            >
              <Route
                path={`${appContext.basepath}/schedules/`}
                element={<ScheduleListing />}
              />
            </Route>
            <Route
              path={`${appContext.basepath}/create-new`}
              element={<CreateNew />}
            />
            <Route
              path={`${appContext.basepath}/standard-reports`}
              element={<StandardReports />}
            />
            <Route
              path={`${appContext.basepath}/admin-standard-reports`}
              element={<AdminStandardReports />} 
            />
            {/* <Route
              path={`${appContext.basepath}/admin-standard-reports/tenants-list/all`}
              element={<TenantsList type={ReportsListType.All}/>}
            /> */}
            <Route
              path={`${appContext.basepath}/standard-reports-view`}
              element={<ProtectedRoute permission={PERMISSIONS.viewReport}><ReportLayout/></ProtectedRoute>}
            >
              <Route 
                path={`${appContext.basepath}/standard-reports-view/:reportId`}
                element={<ReportsView />} />
            </Route>
            <Route
              path={`${appContext.basepath}/reports/reports-templates`}
              element={<ReportsTemplates />}
            />
            <Route
              path={`${appContext.basepath}/folders-all`}
              element={<FoldersAll />}
            />
            <Route
              path={`${appContext.basepath}/dashboard/dashboard-templates`}
              element={<DashboardsTemplates />}
            />
            <Route path={`${appContext.basepath}/dashboards`} element={<DashboardLayout />}>
              <Route
                path={`${appContext.basepath}/dashboards/:dashboardId`}
                element={
                  <ProtectedRoute permission={PERMISSIONS.viewDashboard}>
                    <DashboardsView />
                  </ProtectedRoute>
                }
              />
              <Route
                element={
                  <ProtectedRoute permission={PERMISSIONS.listDashboards} />
                }
              >
                <Route
                  path={`${appContext.basepath}/dashboards/all`}
                  element={<DashboardsList type={DashboardsListType.All} />}
                />
                <Route
                  path={`${appContext.basepath}/dashboards/recent`}
                  element={<DashboardsList type={DashboardsListType.Recent} />}
                />
                <Route
                  path={`${appContext.basepath}/dashboards/public`}
                  element={<DashboardsList type={DashboardsListType.Public} />}
                />
                <Route
                  path={`${appContext.basepath}/dashboards/private`}
                  element={<DashboardsList type={DashboardsListType.Private} />}
                />
                <Route
                  path={`${appContext.basepath}/dashboards/createdByMe`}
                  element={
                    <DashboardsList type={DashboardsListType.CreatedByMe} />
                  }
                />
                <Route
                  path={`${appContext.basepath}/dashboards/sharedWithMe`}
                  element={
                    <DashboardsList type={DashboardsListType.SharedWithMe} />
                  }
                />
              </Route>

              
              <Route
                path={`${appContext.basepath}/dashboards/schedules`}
                element={<DashboardsSchedules />}
              />

              <Route
                path={`${appContext.basepath}/dashboards/:dashboardId/schedules`}
                element={<DashboardsSchedules />}
              />

            </Route>

            <Route
              path={`${appContext.basepath}/dashboards/add`}
              element={
                <ProtectedRoute permission={PERMISSIONS.createDashboard}>
                  <DashboardsAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${appContext.basepath}/dashboards/:dashboardId/edit`}
              element={
                <ProtectedRoute permission={PERMISSIONS.editDashboard}>
                  <DashboardsEdit />
                </ProtectedRoute>
              }
            />


            <Route>
              <Route
                path={`${appContext.basepath}/dashboards/:dashboardId/schedules/add`}
                element={<DashboardsSchedulesAdd />}
              />

              <Route
                path={`${appContext.basepath}/dashboards/:dashboardId/schedules/:scheduleId/edit`}
                element={<DashboardsSchedulesEdit />}
              />
            </Route>


            <Route path={`${appContext.basepath}/reports`} element={<ReportLayout />}>
              <Route
                path={`${appContext.basepath}/reports/:reportId`}
                element={
                  <ProtectedRoute permission={PERMISSIONS.viewReport}>
                    <ReportsView />
                  </ProtectedRoute>
                }
              />
              <Route
                element={<ProtectedRoute permission={PERMISSIONS.listReports} />}
              >
                <Route
                  path={`${appContext.basepath}/reports/all`}
                  element={<ReportsList type={ReportsListType.All} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/recent`}
                  element={<ReportsList type={ReportsListType.Recent} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/public`}
                  element={<ReportsList type={ReportsListType.Public} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/private`}
                  element={<ReportsList type={ReportsListType.Private} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/createdByMe`}
                  element={<ReportsList type={ReportsListType.CreatedByMe} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/sharedWithMe`}
                  element={<ReportsList type={ReportsListType.SharedWithMe} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/folders/:folderId`}
                  element={<ReportsList type={ReportsListType.All} />}
                />
              </Route>

              

              <Route path={`${appContext.basepath}/reports/glossary`} element={<GlossaryReport />} />

              <Route
                element={<ProtectedRoute permission={PERMISSIONS.listFolders} />}
              >
                <Route
                  path={`${appContext.basepath}/reports/folders`}
                  element={<FoldersList type={FoldersListType.All} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/folders/createdByMe`}
                  element={<FoldersList type={FoldersListType.CreatedByMe} />}
                />
                <Route
                  path={`${appContext.basepath}/reports/folders/sharedWithMe`}
                  element={<FoldersList type={FoldersListType.SharedWithMe} />}
                />
              </Route>

              <Route path={`${appContext.basepath}/reports/schedules`} element={<ReportsSchedules />} />

              <Route
                path={`${appContext.basepath}/reports/:reportId/schedules`}
                element={<ReportsSchedules />}
              />

              
            </Route>

            <Route
              path={`${appContext.basepath}/reports/add`}
              element={
                <ProtectedRoute permission={PERMISSIONS.createReport}>
                  <ReportsAdd />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${appContext.basepath}/reports/:reportId/edit`}
              element={
                <ProtectedRoute permission={PERMISSIONS.editReport}>
                  <ReportsEdit />
                </ProtectedRoute>
              }
            />

            <Route>
              <Route
                path={`${appContext.basepath}/reports/:reportId/schedules/add`}
                element={<ReportsSchedulesAdd />}
              />

              <Route
                path={`${appContext.basepath}/reports/:reportId/schedules/:scheduleId/edit`}
                element={<ReportsSchedulesEdit />}
              />
            </Route>

            <Route path={`${appContext.basepath}/tenants`} element={<ReportLayout />}>
              <Route
                element={<ProtectedRoute permission={PERMISSIONS.listReports} />}
              >
                <Route
                  path={`${appContext.basepath}/tenants/all`}
                  element={<TenantsList type={TenantsListType.All} />}
                />
              </Route>
            </Route>
            <Route
              path={`${appContext.basepath}/tenants/add`}
              element={
                <ProtectedRoute permission={PERMISSIONS.createReport}>
                  <TenantAdd />
                </ProtectedRoute>
              }
            />
            <Route path={`${appContext.basepath}/bookmarks`} element={<ReportLayout />}>
              <Route
                element={<ProtectedRoute permission={PERMISSIONS.listReports} />}
              >
                <Route
                  path={`${appContext.basepath}/bookmarks/reports`}
                  element={<BookmarksList type={BookmarksListType.Reports} />}
                />
                <Route
                  path={`${appContext.basepath}/bookmarks/dashboards`}
                  element={<BookmarksList type={BookmarksListType.Dashboards} />}
                  />
              </Route>
            </Route>
            <Route path={`${appContext.basepath}/archive`} element={<ReportLayout />}>
              <Route
                element={<ProtectedRoute permission={PERMISSIONS.listReports} />}
              >
                <Route
                  path={`${appContext.basepath}/archive/reports`}
                  element={<ArchiveList type={ArchiveListType.Reports} />}
                />
                <Route
                  path={`${appContext.basepath}/archive/dashboards`}
                  element={<ArchiveList type={ArchiveListType.Dashboards} />}
                  />

              </Route>
            </Route>
            <Route path={`${appContext.basepath}/access-denied`} element={<AccessDenied />} />
          </Route>
        </Routes>
      </AppContext.Provider>
    </Suspense>
  );
};
export default MainRoutes;
