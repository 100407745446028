import { Button, Input, Typography } from "antd";
import { FormApi } from "final-form";
import { Field, Form } from "react-final-form";
import { FolderCreateUpdate } from "../../../types/folder";

const { Text } = Typography;

type props = {
  onSubmitFolder: any;
  onCancel: any;
  initialValues?: FolderCreateUpdate;
};

const FoldersForm = ({ onSubmitFolder, initialValues, onCancel }: props) => {
  const required = (value: any) => (value ? undefined : "Required");
  const minLength = (length: number) => (value: string) =>
    value.length >= length
      ? undefined
      : `Must be at least ${length} characters long`;
  const composeValidators =
    (...validators: any[]) =>
    (value: any) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

  const onFormSubmit = (values: FolderCreateUpdate) => {
    onSubmitFolder(values);
    currentForm.restart({});
  };

  let currentForm: FormApi<any, Partial<any>>;
  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        currentForm = form;
        return (
          <form id="folderForm" onSubmit={handleSubmit}>
            <Field
              name="name"
              component="input"
              validate={composeValidators(required, minLength(3))}
            >
              {({ input, meta }) => (
                <div>
                  <label>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    {...input}
                    placeholder="Name"
                    status={meta.error && meta.touched ? "error" : ""}
                  />
                  {meta.error && meta.touched && (
                    <Text type="danger">* {meta.error}</Text>
                  )}
                </div>
              )}
            </Field>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
                gap: "10px",
              }}
            >
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting || pristine}
              >
                Ok
              </Button>
            </div>
          </form>
        );
      }}
    ></Form>
  );
};

export default FoldersForm;
