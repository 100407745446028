import { Menu } from "antd";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../app/store";
import { Link } from "../types/layout";
import type { MenuProps } from "antd";
import { layoutSlice } from "./store/layout-slice";
import { push } from "redux-first-history";
import { MainLayoutMenuStyles } from "./main-layout-menu-styles";

type props = {
  links: { [name: string]: Link };
  menuItems: MenuProps["items"];
};

const MainLayoutMenu = ({ links, menuItems }: props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const route = Object.entries(links).find(([k, v]) => {
    return v.link.toLowerCase() === location.pathname.toLowerCase();
  });

  const [currentItem, setCurrentMenuItem] = useState(route?.[1]);

  useEffect(() => {
    dispatch(layoutSlice.actions.updateCurrentItem(currentItem));
    dispatch(layoutSlice.actions.updateShowDrawer(false));
  });

  return (
    <MainLayoutMenuStyles>
      <Menu
        className="menuItems"
        mode="inline"
        defaultSelectedKeys={[route?.[0] || ""]}
        defaultOpenKeys={["reports"]}
        style={{ height: "100%", borderRight: 0}}
        items={menuItems}
        onSelect={({ key }) => {
          setCurrentMenuItem(links[key]);
          dispatch(push(links[key].link));
        }}
        />
    </MainLayoutMenuStyles>
  );
};

export default MainLayoutMenu;