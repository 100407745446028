import React from "react";
import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import { Report } from "../../types/report";
import { Dashboard } from "../../types/dashboard";
import { Cron, Schedule } from "../../types/schedule";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { ListStyles } from "./list-styles";

type props<T> = {
  columns: ColumnsType<T>;
  data: ListResponse<T>;
  setTableParams: React.Dispatch<React.SetStateAction<TableParams>>;
};

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

export const List = <T extends Report | Dashboard | Schedule | Cron>({
  data,
  columns,
  setTableParams,
}: props<T>) => {
  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: false,
    onShowSizeChange: (_: any, pageSize: number) => {
      setTableParams({
        pagination: {
          pageSize: pageSize,
        },
      });
    },
    onChange: (page: number) => {
      setTableParams({
        pagination: {
          current: page,
        },
      });
    },
    pageSizeOptions: [10, 20, 30],
    current: data?.pageNumber,
    pageSize: data?.pageSize,
    total: data?.totalCount,
    // showTotal: (total: number, range: number[]) => `${range[0]} to ${range[1]} of ${total}`,
    showTotal: (total: number, range: number[]) => `Show records`,
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T>
  ) => {
    setTableParams({
      pagination,
      filters,
      sortField: sorter?.field?.toString(),
      sortOrder: sorter?.order?.toString() === "ascend" ? "asc" : "desc",
    });
  };

  return (
    <ListStyles>
      <Table
        columns={columns}
        dataSource={data?.items}
        size="small"
        pagination={paginationOptions}
        rowKey={(record: any) => record.id || record.task_id}
        scroll={{x: '1500', y: "calc(100vh - 13rem)"}}
        // bordered
        // footer={() => ""}
        onChange={(pagination, filters, sorter) =>
          handleTableChange(
            pagination,
            filters,
            (sorter as SorterResult<T>)
              ? (sorter as SorterResult<T>)
              : ((sorter as SorterResult<T>[])[0] as SorterResult<T>)
          )
        }
      />
    </ListStyles>
  );
};

export default List;
