import styled from "styled-components";

export const MainLayoutMenuStyles = styled.div`
  height: 100%;

  .menuItems{
    height: 100%;
    border-right: 0;
    font-size: 1rem;
  }

  .ant-menu-item-group-title{
    font-size: 1rem;
  }

  @media (max-width: 990px) {
    .menuItems{
      font-size: 1.5rem;
    }
  }
  
`;