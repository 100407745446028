import { Layout } from "antd";
import type { MenuProps } from "antd";
import { Link } from "../types/layout";
import MainLayoutMenu from "./main-layout-menu";
const { Sider } = Layout;

export type MenuItem = Required<MenuProps>["items"][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

type props = {
  links: { [name: string]: Link };
  menuItems: MenuProps["items"];
};

const MainLayoutSider = ({ links, menuItems }: props) => {
  return (
    <Sider collapsedWidth={0} breakpoint="lg" width="15%" trigger={null}>
      <MainLayoutMenu links={links} menuItems={menuItems} />
    </Sider>
  );
};

export default MainLayoutSider;
