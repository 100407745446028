import { useState, forwardRef, useImperativeHandle, Ref } from "react";
import { Button, Modal, Select, Skeleton, Tag, Typography } from "antd";
import { useLazySearchUsersQuery } from "../store/user-api-slice";
import {
  useChangeSharePermissionReportMutation,
  useRemoveShareReportMutation,
  useReportSharedWithQuery,
  useShareReportMutation,
  useUpdateReportOwnerMutation,
} from "../../reports/store/report-api-slice";
import { DebounceSelect, UserValue } from "./debounce-select";
import {
  useChangeSharePermissionDashboardMutation,
  useDashboardSharedWithQuery,
  useRemoveShareDashboardMutation,
  useShareDashboardMutation,
  useUpdateDashboardOwnerMutation,
} from "../../dashboards/store/dashboard-api-slice";

const { Text } = Typography;

export enum ShareType {
  Report = 1,
  Dashboard,
}

export interface IShareModal {
  toggleModal: () => void;
  changeId: (id: number) => void;
  changeType: (type: ShareType) => void;
  changeShare: (share: (values: any) => void) => void;
}

const ShareModal = forwardRef((_, ref: Ref<IShareModal>) => {
  useImperativeHandle(ref, () => ({
    toggleModal: toggleModal,
    changeId: changeId,
    changeType: changeType,
    changeShare: changeShare,
  }));

  const [value, setValue] = useState<UserValue[]>([]);

  const [trigger] = useLazySearchUsersQuery();

  const onSearch = async (searchText: string) => {
    let values: {
      key?: string;
      label: React.ReactNode;
      value: string | number;
    }[] = [];
    if (searchText) {
      const { data } = await trigger(searchText);
      values =
        data?.items.map((u) => {
          return {
            key: u.id,
            value: u.id,
            label: (
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    flexGrow: "1",
                  }}
                > */}
                <Text strong>{u.name}</Text>
                {/* <Text
                    type="secondary"
                    ellipsis={true}
                    style={{ width: "200px" }}
                  >
                    {u.email}
                  </Text> */}
                {/* </div> */}
              </div>
            ),
          };
        }) || [];
    }

    return values;
  };

  const [on, setOn] = useState(false);
  const toggleModal = () => setOn(!on);

  const [id, setId] = useState<number>(0);
  const changeId = (id: number) => setId(id);

  const [type, setType] = useState(ShareType.Report);
  const changeType = (type: ShareType) => setType(type);

  const [share, setShare] = useState<(values: any) => void>();
  const changeShare = (share: (values: any) => void) =>
    setShare((values: any) => share(values));

  const { data: dataReport } = useReportSharedWithQuery(id, {
    skip: type !== ShareType.Report || (type === ShareType.Report && id === 0),
  });

  const { data: dataDashboard } = useDashboardSharedWithQuery(id, {
    skip:
      type !== ShareType.Dashboard ||
      (type === ShareType.Dashboard && id === 0),
  });

  const data = dataReport || dataDashboard || [];

  const [shareReport] = useShareReportMutation();
  const [changeSharePermissionReport] =
    useChangeSharePermissionReportMutation();
  const [removeShareReport] = useRemoveShareReportMutation();

  const [shareDashboard] = useShareDashboardMutation();
  const [changeSharePermissionDashboard] =
    useChangeSharePermissionDashboardMutation();
  const [removeShareDashboard] = useRemoveShareDashboardMutation();
  const [updateReportOwner] = useUpdateReportOwnerMutation();
  const [updateDashboardOwner] = useUpdateDashboardOwnerMutation();

  const handleShare = () => {
    if (type === ShareType.Report)
      shareReport({
        reportId: id,
        usersId: value.map((v) => v.value),
      });
    else if (type === ShareType.Dashboard)
      shareDashboard({
        dashboardId: id,
        usersId: value.map((v) => v.value),
      });

    setValue([]);
  };

  const handleChangePermission = (userId: string, allowEdit: boolean) => {
    if (type === ShareType.Report)
      changeSharePermissionReport({
        reportId: id,
        userId: userId,
        allowEdit: allowEdit,
      });
    else if (type === ShareType.Dashboard)
      changeSharePermissionDashboard({
        dashboardId: id,
        userId: userId,
        allowEdit: allowEdit,
      });
  };

  const handleRemoveShare = (userId: string) => {
    if (type === ShareType.Report)
      removeShareReport({
        reportId: id,
        userId: userId,
      });
    else if (type === ShareType.Dashboard)
      removeShareDashboard({
        dashboardId: id,
        userId: userId,
      });
  };

  const handleCancel = () => {
    setValue([]);
    setOn(false);
  };

  const showConfirmOwnership = (userId: string) => {
    Modal.confirm({
      title: "Are you sure you want to change the owner?",
      content:
        "This action will transfer ownership rights and responsibilities to the selected user.",
      onOk() {
        if (type === ShareType.Report)
          updateReportOwner({
            reportId: id,
            userId: userId,
          });
        else if (type === ShareType.Dashboard)
          updateDashboardOwner({
            dashboardId: id,
            userId: userId,
          });

        handleCancel();
      },
    });
  };

  return (
    <Modal
      width={600}
      title={type === ShareType.Report ? "Share Report" : "Share Dashboard"}
      open={on}
      onCancel={handleCancel}
      footer={[]}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <DebounceSelect
          size="large"
          mode="tags"
          value={value}
          placeholder="Search User by Name or Email Address"
          fetchOptions={onSearch}
          onSelect={(newValue) => {
            if (newValue.label) setValue([...value, newValue as UserValue]);
          }}
          onDeselect={(removeValue) => {
            setValue(value.filter((v) => v.value !== removeValue.value));
          }}
          style={{ width: "100%" }}
        />
        <Button type="primary" onClick={handleShare}>
          Share
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "10px",
        }}
      >
        <Text strong>People with access</Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
            gap: "25px",
          }}
        >
          {data ? (
            data.map((u) => (
              <div
                key={u.userId}
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    flexGrow: "1",
                  }}
                >
                  <div>
                    <Text strong>{u.name}</Text>
                    {!u.isOwner ? (
                      <Button
                        type="link"
                        onClick={() => handleRemoveShare(u.userId)}
                      >
                        remove
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                  <Text type="secondary">{u.email}</Text>
                </div>
                {u.isOwner ? (
                  <Tag color="blue">Owner</Tag>
                ) : (
                  <Select
                    defaultValue={u.allowEdit}
                    style={{ width: 100 }}
                    onChange={(value: any) =>
                      handleChangePermission(u.userId, value)
                    }
                    options={[
                      { value: false, label: "Can View" },
                      { value: true, label: "Can Edit" },
                    ]}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Button
                          style={{ maxHeight: "256px", padding: "5px 12px" }}
                          type="text"
                          onClick={() => showConfirmOwnership(u.userId)}
                        >
                          Ownership
                        </Button>
                      </>
                    )}
                  />
                )}
              </div>
            ))
          ) : (
            <Skeleton avatar paragraph={{ rows: 2 }} />
          )}
        </div>
      </div>
    </Modal>
  );
});

export default ShareModal;
