import { useEffect, useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { layoutSlice } from "../../layout/store/layout-slice";
import { useGetAllFoldersQuery } from "./store/folder-api-slice";
import { push, goBack } from "redux-first-history";
import { AppContext, store } from "../../app/store";
import { ModalUtility } from "../../layout/components/modal-manager";
import FoldersModal, { FolderCRUDType, IFoldersModal } from "./folders-modal";
import './folder-all.scss'
import Title from "antd/es/typography/Title";
import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { hasPermission } from "../auth/store/auth-slice";
import { PERMISSIONS } from "../auth/permissions/permission-maps";

const  FoldersAll = () => {
  const foldersModalRef = useRef<IFoldersModal>(null);
  // const foldersModalRef = ModalUtility.getPropertyByName("foldersModalRef");

  const showModal = () => {
    foldersModalRef?.current?.toggleModal();
    // (foldersModalRef.current as IFoldersModal)?.changeType(
    //   FolderCRUDType.Create
    // );
  };
//   let folders = [
//     {
//         "id": 5,
//         "name": "Bugs",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 11
//     },
//     {
//         "id": 8,
//         "name": "Demo Power BI",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 2
//     },
//     {
//         "id": 29,
//         "name": "Demo Test",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 1
//     },
//     {
//         "id": 16,
//         "name": "DevScope-Tests",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 8
//     },
//     {
//         "id": 20,
//         "name": "Financial",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 3
//     },
//     {
//         "id": 12,
//         "name": "JL Test",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 17
//     },
//     {
//         "id": 26,
//         "name": "M A Demo",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 1
//     },
//     {
//         "id": 24,
//         "name": "MARRIOTT",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 1
//     },
//     {
//         "id": 4,
//         "name": "Performance",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 5
//     },
//     {
//         "id": 10,
//         "name": "RETEST",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 12
//     },
//     {
//         "id": 11,
//         "name": "RETEST1",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 1
//     },
//     {
//         "id": 2,
//         "name": "RS_Jobs1",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 2
//     },
//     {
//         "id": 15,
//         "name": "Sample_Shri",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 29
//     },
//     {
//         "id": 23,
//         "name": "Simplify QA KT Session",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 2
//     },
//     {
//         "id": 25,
//         "name": "test",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 11
//     },
//     {
//         "id": 1,
//         "name": "test2",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 18
//     },
//     {
//         "id": 14,
//         "name": "teste",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 5
//     },
//     {
//         "id": 28,
//         "name": "UI ",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 4
//     },
//     {
//         "id": 7,
//         "name": "Uzman Test Reports",
//         "published": false,
//         "createdBy": "Michael Murray",
//         "numberOfReports": 9
//     }
// ]
const { data: allFolders = [], isLoading } = useGetAllFoldersQuery(undefined);
const [appContext] = useContext(AppContext)
  const dispatch = useDispatch()
const [searchQuery, setSearchQuery] = useState("");

const filteredFolders = allFolders.filter(folder =>
    folder.name.toLowerCase().includes(searchQuery.toLowerCase())
  );



  return (
    <div className="analytics-wrapper folder-listing-page">
      <div className="sub-header p-24 bg-light d-flex align-items-center" style={{gap:'0.5rem'}}>
        {/* <div>
        <h3 className="font-weight-semibold mb-0">Collections</h3>
          <p className="small"></p>
        </div> */}
        {/* <Title
          level={4}
          style={{
            // marginBottom: 0,
            // marginLeft: "10px",
            margin: 0
          }}
        >Collections
        </Title> */}
        
        <h3 className="font-weight-semibold mb-0">Collections</h3>

        {
          hasPermission(store.getState(), PERMISSIONS.createReport)? <Button onClick={showModal} icon={<PlusOutlined/>} type="primary" shape="circle" style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}/> : null
        }

        <div className="d-flex" style={{flexGrow: 1}}>
          <div className="position-relative search-head" style={{ marginLeft: 'auto'}}>
            <input type="text"  className="form-control" placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
              <i className="material-icons search-icon">search</i>
          </div>
        </div>
       
        
      </div>
      <div className="folder-list" style={{height:'calc(100vh - 152px)'}}>
        {filteredFolders.length===0? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
             {isLoading?'Loading...':'No data'}
          </span>
        } /> : null}
        <ul className="p-24">
            {
                filteredFolders.map(folder=>(
                    <li key={folder.id} onClick={() => dispatch(push(`${appContext.basepath}/reports/folders/${folder.id}?folderName=${folder.name}`))}>
                        <figure>
                            {folder.numberOfReports===0 ? <span className='material-icons-outlined empty'>folder_off</span> :  <span className='material-icons'>folder</span> } 
                        </figure>
                        <div className="caption">
                            <p className="font-weight-medium text-truncate">{folder.name}</p>
                            <p className="small text-truncate">{`${folder.numberOfReports} reports`}</p>
                        </div> 
                    </li>
                ))
            }
          

          {/* <li className="empty">
            <figure>
              <img src="assets/images/folder-empty.svg" alt="" width="54" height="51"/>    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Finance</p>
                <p className="small">0 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">KPIS</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Ssmple Shared</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li>
          <li>
            <figure>
              <img src="assets/images/folder-default.svg" alt="" width="54" height="51">    
            </figure>
               <div className="caption">
                <p className="font-weight-medium">Audit</p>
                <p className="small">10 reports</p>
               </div> 
          </li> */}
        </ul>
      </div>
      <FoldersModal ref={foldersModalRef} folderId={+("0")} />
    </div>
 
    
  );
};

export default FoldersAll;
