import { api } from "../../../app/baseQueryWithReauth";

export const authApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<string[], void>({
      query: () => ({
        url: `/rolePermission/getRoles`,
        method: "GET",
      }),
      // providesTags: ["Roles"],
    }),
  }),
});

export const { useGetRolesQuery } = authApiSlice;
