export enum PERMISSIONS {
  listReports = "ListReports",
  viewReport = "ViewReport",
  createReport = "CreateReport",
  editReport = "EditReport",
  cloneReport = "CloneReport",
  deleteReport = "DeleteReport",
  exportReport = "ExportReport",
  shareReport = "ShareReport",
  scheduleReport = "ScheduleReport",

  listDashboards = "ListDashboards",
  viewDashboard = "ViewDashboard",
  createDashboard = "CreateDashboard",
  editDashboard = "EditDashboard",
  cloneDashboard = "CloneDashboard",
  deleteDashboard = "DeleteDashboard",
  exportDashboard = "ExportDashboard",
  shareDashboard = "ShareDashboard",
  scheduleDashboard = "ScheduleDashboard",

  listFolders = "ListFolders",
  createFolder = "CreateFolder",
  editFolder = "EditFolder",
  deleteFolder = "DeleteFolder",
  shareFolder = "ShareFolder",

  editStandardReport = "EditStandardReport"
}

// export const PERMISSIONS_MAP = {
//   Reports: [
//     PERMISSIONS.listFolders,
//     PERMISSIONS.viewReport,
//     PERMISSIONS.createReport,
//     PERMISSIONS.editFolder,
//     PERMISSIONS.cloneReport,
//     PERMISSIONS.deleteReport,
//     PERMISSIONS.exportReport,
//     PERMISSIONS.shareReport,
//     PERMISSIONS.scheduleReport
//   ],
//   Folders: [
//     PERMISSIONS.listFolders,
//     PERMISSIONS.createFolder,
//     PERMISSIONS.editFolder,
//     PERMISSIONS.deleteFolder,
//   ]
// }
