import { api } from "../../../app/baseQueryWithReauth";
import { Folder, FolderCreateUpdate } from "../../../types/folder";

const folderApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getAllFolders: build.query<Folder[], void>({
      query: () => "/folder/getAllFolders",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Folder" as const, id })),
              { type: "Folder", id: "LIST" },
            ]
          : [{ type: "Folder", id: "LIST" }],
    }),
    getFoldersCreatedByMe: build.query<Folder[], void>({
      query: () => "/folder/getFoldersCreatedByMe",
      transformResponse: (response: Folder[], meta, arg) => response,
      providesTags: ["Folder"],
    }),
    getFoldersSharedWithMe: build.query<Folder[], void>({
      query: () => "/folder/getFoldersSharedWithMe",
      transformResponse: (response: Folder[], meta, arg) => response,
      providesTags: ["Folder"],
    }),
    getFolder: build.query<FolderCreateUpdate, number>({
      query: (id) => ({
        url: `folder/${id}`,
      }),
      transformResponse: (response: Folder) => {
        return { id: response.id, name: response.name };
      },
      providesTags: (result, error, id) => [{ type: "Folder", id }],
    }),
    createFolder: build.mutation<
      FolderCreateUpdate,
      Omit<FolderCreateUpdate, "id">
    >({
      query: (initialFolder: FolderCreateUpdate) => ({
        url: "/folder",
        method: "POST",
        body: initialFolder,
      }),
      invalidatesTags: ["Folder"],
    }),
    updateFolder: build.mutation<
      FolderCreateUpdate,
      Partial<FolderCreateUpdate>
    >({
      query: (data) => ({
        url: `folder/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Folder", id }],
    }),
    deleteFolder: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/folder/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Folder"],
    }),
  }),
});

export const {
  useGetAllFoldersQuery,
  useGetFoldersCreatedByMeQuery,
  useGetFoldersSharedWithMeQuery,
  useGetFolderQuery,
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,
} = folderApiSlice;
