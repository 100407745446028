import React from "react";
import { createRoot } from "react-dom/client";
import { store, history, AppContext } from "./app/store";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import MainRoutes from "./routes/routes";
import { refreshToken } from "./features/auth/store/auth-slice";
import { push } from "redux-first-history";
import { App } from "antd";

export interface AnalyticsProps {
  element: HTMLElement;
  type: AnalyticsType;
  accessToken: string;
  program: string;
  apiUrl: string;
  pbiEvents: string[];
  eventHandler: (event: string, type: string) => void;
  basepath: string
  onInit: (store: any) => void
}

export enum AnalyticsType {
  Report = "report",
  Create = "create",
  Standard = "standard",
  Dashboard = "dashboard",
  Schedule = "schedule",
  Glossary = "glossary",
  Folder = "folder"
}

export enum EventHandlerType {
  SDK = "SDK",
  PowerBi = "PowerBi"
}

export enum EventHandlerStatus {
  Loading = "Loading",
  Loaded = "Loaded",
  Error = "Error"
}

let entryAccessToken = "";
let entryProgram = "";
let entryType = "";
let entryApiUrl = "";
let entryPbiEvents :string[] = [];
let entryEventHandler: (event: string, type: string) => void;

export const GetAccessToken = () => {
  return entryAccessToken;
};

export const GetProgram = () => {
  return entryProgram;
};

export const GetApiUrl = () => {
  return entryApiUrl;
};

export const GetListEvents = () => {
  return entryPbiEvents;
};

export const GetType = () => {
  return entryType;
};

export const SetAccessToken = (token: string) => {
  console.log("Analytics - SetAccessToken");
  store.dispatch(refreshToken({ token }));
};

// export const Redirect = (path: string) => {
//   store.dispatch(updateRedirectPath({ redirectPath: path }));
// };

export const _EventHandler = (event: string, type: string) => {
  if(entryEventHandler){
    entryEventHandler(event, type);
  }else{
    console.log(event+"-"+type)
  }
};



export const Load = ({
  element,
  type,
  accessToken,
  program,
  apiUrl,
  pbiEvents,
  eventHandler,
  onInit,
  basepath = ''
}: 
AnalyticsProps) => {
  entryType = type;
  entryAccessToken = accessToken;
  entryProgram = program;
  entryApiUrl = apiUrl;
  entryPbiEvents = pbiEvents;
  entryEventHandler = eventHandler;

  if(onInit) {
    onInit({
      redirect: (url: string) => {
        store.dispatch(push(url))
      }
    })
  }

  return createRoot(element as HTMLElement).render(
    <React.StrictMode>
      <App className="app" style={{height:'100vh',display:'flex', overflowY:'auto'}}>
      
        <Provider store={store}>
          <Router history={history}>
            <MainRoutes basepath={basepath} />
          </Router>
        </Provider>

      </App>
    </React.StrictMode>
  );
};

